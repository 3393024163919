<template>
  <div data-app>
    <v-card>
      <v-card-title>
        Sales points
        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="salesPointCollection"
        :search="search"
        :sort-by="['name']"
        @click:row="handleClickItem"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <SalesPointModalForm
              :modalData="modalData"
              :editedItem="editedItem"
              :permissions="permissions"
              :userGroups="userGroupCollection"
              :sites="siteCollection"
              :statuses="statuses"
              @closeModalForm="handleCloseModalForm"
              @saveModalForm="handleSaveModalForm"
            ></SalesPointModalForm>
            <v-spacer></v-spacer>
            <!-- <v-checkbox
              v-model="showAll"
              label="Active departments"
            ></v-checkbox> -->

            <v-dialog v-model="dialogDelete" max-width="500px">
              <v-card>
                <v-card-title class="headline"
                  >Are you sure you want to delete this item?</v-card-title
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="closeDelete"
                    >Cancel</v-btn
                  >
                  <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                    >OK</v-btn
                  >
                  <v-spacer></v-spacer>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
        </template>
        <!-- <template v-slot:item.status="{ item }">
          <span v-if="statuses.system">{{
            statuses.companySettings.department[item.status].value
          }}</span>
        </template> -->
        <template v-slot:item.actions="{ item }">
          <!-- <router-link
            :to="`/sales/salesPoint/${item.id}`"
            v-if="permissionCan('view')"
          >
            <v-icon small class="mr-2"> mdi-pencil </v-icon>
          </router-link> -->
          <v-icon
            v-if="permissionCan('delete') && item.deletable"
            small
            @click="deleteItem(item)"
          >
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
      <!-- {{ permissions }} -->
    </v-card>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ApiService from "@/core/services/api.service";
import SalesPointModalForm, { initialFormData } from "./SalesPointModalForm";
import { listModelsMixins } from "@/view/mixins/listModelsMixins.js";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  FETCH_USER_GROUPS,
  USER_GROUP_COLLECTION,
} from "@/core/services/store/userGroup.module.js";

import {
  FETCH_SITE,
  SITE_COLLECTION,
} from "@/core/services/store/site.module.js";

export const ENDPOINT = "sales/salesPoint/";

export default {
  name: "salesPoints",
  components: { SalesPointModalForm },
  mixins: [listModelsMixins],
  data() {
    return {
      loader: false,
      showAll: false,
      search: "",
      collection: [],
      permissions: [],
      headers: [
        {
          text: "Sales Point Name",
          value: "name",
        },
        // { text: "Status", value: "status" },
        { text: "Actions", value: "actions", sortable: false },
      ],

      modalData: {
        dialog: false,
        loading: false,
        // editedItem: Object.assign({}, initialFormData),
      },
      editedItem: Object.assign({}, initialFormData),

      dialogDelete: false,
      routePath: "/sales/salesPoint/",
    };
  },

  computed: {
    ...mapGetters([
      "salesPointCollection",
      "statuses",
      USER_GROUP_COLLECTION,
      SITE_COLLECTION,
    ]),
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    // showAll() {
    //   if (this.companyTypeCollection.length > 0) {
    //     this.filterCollection(this[DEPARTMENT_COLLECTION]);
    //   }
    // },
    "$route.params.id": {
      handler: function (id) {
        if (id) {
          this.editItem(id);
        }
      },
      deep: true,
      immediate: true,
    },
    salesPointCollection(val) {
      this.filterCollection(val);
    },
  },

  methods: {
    ...mapActions(["fetchSalesPoint", FETCH_USER_GROUPS, FETCH_SITE]),
    editItem(id) {
      ApiService.get(ENDPOINT + id)
        .then(({ data }) => {
          this.editedItem = Object.assign({}, data);
          this.modalData.dialog = true;
          this.modalData.userGroups = this[USER_GROUP_COLLECTION];
          this.modalData.sites = this[SITE_COLLECTION];
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
    },

    deleteItem(item) {
      this.editedItem = Object.assign({}, item);
      this.dialogDelete = true;
    },

    deleteItemConfirm() {
      ApiService.delete(ENDPOINT + this.editedItem.id)
        .then(() => {
          this.fetchSalesPoint();
          this.fetchUserGroups();
          this.FETCH_SITE;
        })
        .catch((error) => {
          console.log("Error!: ", error);
        });
      this.closeDelete();
    },

    handleCloseModalForm() {
      this.modalData.dialog = false;
      this.fetchSalesPoint();
      this.editedItem = Object.assign({}, initialFormData);
      if (this.$route.name !== "salesPoints")
        this.$router.push({ name: "salesPoints" });
    },

    closeDelete() {
      this.editedItem = Object.assign({}, initialFormData);
      this.dialogDelete = false;
    },

    // handleSaveModalForm() {
    //   // this.handleCloseModalForm();
    // },

    filterCollection(coll) {
      if (!this.showAll) {
        this.collection = coll;
      } else {
        this.collection = coll.filter((item) => {
          return item.status == 1;
        });
      }
    },

    // handleClickItem(item) {
    //   if (this.permissionCan("view") && !this.dialogDelete) {
    //     this.$router.push(`/sales/salesPoint/${item.id}`);
    //   }
    // },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "sales.salesPoint." + action
      );
    },

    setPermissions() {
      if (localStorage.permissions) {
        this.permissions = JSON.parse(localStorage.permissions).filter(
          (item) => item.name.indexOf("salesPoint") > -1
        );
      }
    },
  },

  async mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Sales point" }]);
    this.fetchSalesPoint();
    this.fetchUserGroups();
    this.fetchSite();
    this.setPermissions();
  },
};
</script>
