<template>
  <v-dialog v-model="modalData.dialog" max-width="650px" persistent>
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        v-if="permissionCan('create')"
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        @click="handleNew"
      >
        New Sales Point
      </v-btn>
    </template>

    <v-card>
      <v-form v-model="formValid" ref="form">
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline">{{ formTitle }} </span>
              <v-btn
                @click="handleCloseModalForm"
                style="float: right; cursor: pointer"
                icon
                color="#26223c"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="3" md="3">
                <v-select
                  v-if="statuses.system"
                  v-model="formModel.status"
                  :items="statuses.sales.salesPoint"
                  label="Status"
                  item-text="value"
                  item-value="key"
                  :id="dynamicID"
                />
              </v-col>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  v-model="formModel.name"
                  label="Sales Point Name"
                  :rules="nameRules"
                  :id="dynamicID"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.user_group_id"
                  :items="userGroups"
                  label="Related User Group"
                  item-text="groupName"
                  item-value="id"
                  :id="dynamicID"
                />
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="formModel.site_id"
                  :items="sites"
                  label="Related Site"
                  item-text="name"
                  item-value="id"
                  :id="dynamicID"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="handleCloseModalForm">
            Cancel
          </v-btn>
          <v-btn
            v-if="
              (permissionCan('create') && isNotEditedItem) ||
              permissionCan('update')
            "
            color="blue darken-1"
            text
            @click="handleSaveModalForm"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-snackbar v-model="snackbar" :timeout="2000">
      Successfully saved
      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </v-dialog>
</template>

<script>
// import { mapGetters, mapActions } from "vuex";
import { ENDPOINT } from "./SalesPoints";
//import i18nService from "@/core/services/i18n.service.js";
import ApiService from "@/core/services/api.service";

export const initialFormData = {
  id: null,
  name: null,
  status: 1,
  user_group_id: null,
  site_id: null,
};

export default {
  name: "SalesPointModalForm",
  props: [
    "modalData",
    "editedItem",
    "permissions",
    "userGroups",
    "statuses",
    "sites",
  ],
  data() {
    return {
      loader: false,
      formModel: initialFormData,
      formValid: false,
      messages: {},
      nameRules: [
        (v) => !!v || "Sales point name is required",
        (v) =>
          (!!v && v.length < 255) ||
          "Sales point name must be max 255 characters",
      ],
    };
  },
  computed: {
    isNotEditedItem() {
      if (this.editedItem.id) {
        return 0;
      } else {
        return 1;
      }
    },
    // ...mapGetters([CURRENCY_COLLECTION]),
    formTitle() {
      return this.editedItem.id ? "Edit sales point" : "New sales point";
    },
    dynamicID() {
      let text = "dynamicID";
      let chars = "abcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 10; i++) {
        text += chars.charAt(Math.floor(Math.random() * chars.length));
      }

      return text;
    },
  },
  watch: {
    // modalData: {
    //   deep: true,
    //   handler(value) {
    //     this.formModel = value.editedItem;
    //     this.setTranslatedAttributes();
    //   },
    // },
    editedItem(value) {
      this.formModel = value;
      this.resetErrorMessages();
    },
  },
  methods: {
    // ...mapActions([FETCH_CURRENCY]),
    handleCloseModalForm() {
      this.resetErrorMessages();
      this.$emit("closeModalForm");
    },

    handleSaveModalForm() {
      let model = Object.assign({}, this.formModel);

      this.$refs.form.validate();
      if (!this.formValid) return;

      // console.error("this.formValid", this.formValid);
      if (this.formValid) {
        this.resetErrorMessages();
        this.modalData.loading = true;
        this.loader = true;
        if (model.id) {
          // alert("PUT " + model.id);
          ApiService.put(ENDPOINT + model.id, model)
            .then(() => {
              this.modalData.loading = false;
              this.snackbar = true;
              this.$emit("saveModalForm", Object.assign({}, this.formModel));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              }
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        } else {
          //create model

          ApiService.post(ENDPOINT, model)
            .then(({ data }) => {
              console.log(data);
              this.modalData.loading = false;
              this.snackbar = true;
              this.$emit("saveModalForm", Object.assign({}, data));
            })
            .catch((error) => {
              if (error.response) {
                let errors = error.response.data;
                if (errors) {
                  for (let field in errors) {
                    this.setError(field, errors[field][0]);
                  }
                }
              } else if (error.request) {
                // The request was made but no response was received
                console.log(error.request);
              } else {
                // Something happened in setting up the request that triggered an Error
                console.log("Error", error.message);
              }
              console.log("Error!: ", error);
              this.modalData.loading = false;
            })
            .finally(() => {
              this.loader = false;
            });
        }
      }
    },

    handleNew() {
      this.resetErrorMessages();
    },

    resetErrorMessages() {
      let errorData = {};

      this.messages = errorData;
    },

    setError(prop, value) {
      this.messages[prop] = value;
    },

    permissionCan(action) {
      return this.permissions.find(
        (x) => x.name == "sales.salesPoint." + action
      );
    },
  },

  async mounted() {
    // this.fetchCurrency();
  },
};
</script>
